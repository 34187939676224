import { motion } from "framer-motion";
import almondMilk from "./almond-milk.svg";

const defaultAnimation = (duration = 0.5) => ({
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1 },
  transition: { duration },
});

const App = () => (
  <div id="landing-splash">
    <motion.img
      src={almondMilk}
      alt="almond milk creative development logo"
      {...defaultAnimation()}
    />
    <motion.h1 {...defaultAnimation(1)}>Creative Development</motion.h1>
    <motion.p {...defaultAnimation(1.5)}>late fall of 2022</motion.p>
    <a href="mailto:hello@almondmilk.dev">get in touch</a>
  </div>
);

export default App;
